import styled from 'styled-components'

const PageWrapper = styled.div`
  display: flex;
  flex-direction: column;

  padding: 10rem 0 0;
  min-height: 100vh;
`

export default PageWrapper